import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import TreatmentHero from "../../components/treatment-hero";
import ReviewSection from "../../components/review-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import TreatmentCard from "../../components/treatment-card";
import PhotoLeft from "../../components/photo-left";
import { Button } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { checkPropertiesForNull } from "../../utils/objectHelper";
import { SafeHtmlParser } from "../../components/safe-html-parser";
import uuid4 from "uuid4";

const TailoredSkincarePage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "Logo-footer" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "tailored-skincare" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        tailoredSkincarePageFieldGroups {
          tailoredSkincareIntroSection {
            heading
            subHeading
            title
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          tailoredSkincareReviewSection {
            heading
            subHeading
          }
          tailoredSkincareServiceSection {
            heading
            subHeading
            items {
              heading
              content
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
            cta {
              title
              target
              url
            }
          }
          tailoredSkincareTwoColumnWithCtaSection {
            content
            heading
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, tailoredSkincarePageFieldGroups, slug },
  } = data;

  const {
    tailoredSkincareIntroSection,
    tailoredSkincareReviewSection,
    tailoredSkincareServiceSection,
    tailoredSkincareTwoColumnWithCtaSection,
  } = tailoredSkincarePageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.dentalhousepinner.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
    name: "Dental House Pinner",
    url: "https://www.dentalhousepinner.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
      image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Pinner",
      url: "https://www.dentalhousepinner.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhousepinner.co.uk",
      datePublished: "2023-08-23",
      reviewBody:
        "As someone terrified of dentists, it's been a pleasure being a patient of Dental House. Dr Shah's been a godsend, a truly welcoming presence who never fails to put you at ease. Clearly explains each procedure and how it should feel. The whole team are great!",
      author: {
        "@type": "Person",
        name: "N R",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Pinner",
        sameAs: "https://www.dentalhousepinner.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Tailored Skincare",
        item: {
          url: `${siteUrl}/treatments/tailored-skincare`,
          id: `${siteUrl}/treatments/tailored-skincare`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/tailored-skincare`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {tailoredSkincareIntroSection &&
          !checkPropertiesForNull(tailoredSkincareIntroSection, [
            "heading",
          ]) && (
            <TreatmentHero
              noSmallHeading
              objPos="right top"
              heading1={tailoredSkincareIntroSection?.title}
              img={
                tailoredSkincareIntroSection?.image.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imgAlt={tailoredSkincareIntroSection?.image.node?.altText}
              heading2={tailoredSkincareIntroSection?.heading}
              headingSmall={tailoredSkincareIntroSection?.subHeading}
              text={
                <SafeHtmlParser
                  htmlContent={tailoredSkincareIntroSection?.description}
                />
              }
              btnLink={tailoredSkincareIntroSection?.cta.url}
              btnText={tailoredSkincareIntroSection?.cta?.title}
            />
          )}

        <ReviewSection
          heading={
            tailoredSkincareReviewSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            tailoredSkincareReviewSection?.subHeading ||
            "WHAT PEOPLE SAY ABOUT US"
          }
          page="Tailored Skincare"
        />

        {tailoredSkincareServiceSection &&
          !checkPropertiesForNull(tailoredSkincareServiceSection, [
            "heading",
          ]) && (
            <section className="py-5 pb-lg-7">
              <Container>
                <Row>
                  <Col className="text-center">
                    <p className="text-accent jost-bold fs-6">
                      {tailoredSkincareServiceSection?.subHeading}
                    </p>
                    <h2 className="text-text-secondary display-4 pb-4">
                      {tailoredSkincareServiceSection?.heading}
                    </h2>
                  </Col>
                </Row>
                {tailoredSkincareServiceSection.items &&
                  tailoredSkincareServiceSection.items.length > 0 && (
                    <Row className="pt-5 h-100 justify-content-center">
                      {" "}
                      {tailoredSkincareServiceSection.items.map((cardItem) => (
                        <TreatmentCard
                          key={uuid4()}
                          extraPadding
                          headingHeight="60px"
                          objPos="top "
                          image={
                            cardItem?.image.node?.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          imgAlt={cardItem?.image.node?.altText}
                          title={cardItem?.heading}
                          text={
                            <SafeHtmlParser htmlContent={cardItem?.content} />
                          }
                          span={4}
                          price={null}
                          shorter="image-shorter"
                        />
                      ))}
                    </Row>
                  )}
                {tailoredSkincareServiceSection.cta &&
                  tailoredSkincareServiceSection.cta.url && (
                    <Row>
                      <Col>
                        <div className="text-center">
                          <Button
                            className="text-white mt-5 mt-lg-6 py-3 px-5 w-100 w-md-auto"
                            variant="accent"
                            href={tailoredSkincareServiceSection.cta?.url}
                            target={
                              tailoredSkincareServiceSection.cta?.target ||
                              "_blank"
                            }
                            rel="noreferrer"
                          >
                            {tailoredSkincareServiceSection.cta?.title}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
              </Container>
            </section>
          )}

        {tailoredSkincareTwoColumnWithCtaSection &&
          !checkPropertiesForNull(tailoredSkincareTwoColumnWithCtaSection, [
            "heading",
          ]) && (
            <section>
              {" "}
              <PhotoLeft
                imgHeight="40rem"
                img={
                  tailoredSkincareTwoColumnWithCtaSection?.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imgAlt={
                  tailoredSkincareTwoColumnWithCtaSection?.image.node?.altText
                }
                text={
                  <div className="text-white text-center text-lg-start">
                    <h2 className="display-4 pb-4">
                      {tailoredSkincareTwoColumnWithCtaSection?.heading}
                    </h2>
                    <GatsbyImage
                      className="mb-4 w-100 d-lg-none"
                      image={
                        tailoredSkincareTwoColumnWithCtaSection?.image.node
                          ?.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={
                        tailoredSkincareTwoColumnWithCtaSection?.image.node
                          ?.altText
                      }
                    />
                    <div className="content-white">
                      <SafeHtmlParser
                        htmlContent={
                          tailoredSkincareTwoColumnWithCtaSection?.content
                        }
                      />
                    </div>

                    {tailoredSkincareTwoColumnWithCtaSection &&
                      tailoredSkincareTwoColumnWithCtaSection.cta && (
                        <Button
                          className="text-white mt-lg-4 py-3 px-5 w-100 w-md-auto"
                          variant="accent"
                          href={
                            tailoredSkincareTwoColumnWithCtaSection?.cta.url
                          }
                          target={
                            tailoredSkincareTwoColumnWithCtaSection.cta
                              .target ?? "_blank"
                          }
                          rel="noreferrer"
                        >
                          {tailoredSkincareTwoColumnWithCtaSection?.cta.title}
                        </Button>
                      )}
                  </div>
                }
                bgColour="bg-primary"
              />
            </section>
          )}
      </Layout>
    </>
  );
};

export default TailoredSkincarePage;
